(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-single-rows/assets/javascripts/coupon-overview-single-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-single-rows/assets/javascripts/coupon-overview-single-rows.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  Coupon,
  useCouponContext
} = svs.components.tipsen.couponComponents;
const {
  DescriptionSize
} = svs.components.lbUi.participants.constants;
const {
  selectNumberOfStartedBoards
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  CouponOverviewSingleRow
} = svs.components.tipsen.couponOverviewSingleRows;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const CouponOverviewSingleRows = _ref => {
  let {
    style = {}
  } = _ref;
  const {
    couponRows,
    couponId
  } = useCouponContext();
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const isMediumOrLarge = useMediaQuery(breakpoints.up(BREAKPOINT_AM));
  const numberOfStartedCouponBoards = useSelector(state => selectNumberOfStartedBoards(state, couponId));
  const startedCouponBoards = new Array(numberOfStartedCouponBoards).fill(undefined);
  let nameSize = DescriptionSize.Full;
  if (!isMediumOrLarge && numberOfStartedCouponBoards > 6 || isExtraSmall && numberOfStartedCouponBoards > 2) {
    nameSize = DescriptionSize.Short;
  }
  return React.createElement("div", {
    className: "pg_coupon_overview_single_rows",
    style: style
  }, React.createElement("div", {
    className: "pg_coupon_overview_single_rows__description"
  }, React.createElement("div", {
    className: "pg_coupon_overview_single_rows__description__header"
  }), couponRows.map(row => React.createElement("div", {
    className: "pg_coupon_overview_single_rows__description__row",
    key: row.eventNumber
  }, React.createElement("div", null, row.eventNumber, ".\xA0"), React.createElement("div", {
    className: "pg_coupon_overview_single_rows__description__row__participants"
  }, React.createElement(Coupon.Participant, {
    nameSize: nameSize,
    participant: row.homeParticipant
  }), "\xA0-\xA0", React.createElement(Coupon.Participant, {
    nameSize: nameSize,
    participant: row.awayParticipant
  }))))), React.createElement("div", {
    className: "pg_coupon_overview_single_rows__row"
  }, startedCouponBoards.map((_, boardIndex) => {
    const currentKey = "".concat(couponId, "_").concat(boardIndex);
    return React.createElement(CouponOverviewSingleRow, {
      boardIndex: boardIndex,
      key: currentKey
    });
  })));
};
setGlobal('svs.components.tipsen.couponOverviewSingleRows.CouponOverviewSingleRows', CouponOverviewSingleRows);

 })(window);