(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-single-rows/assets/javascripts/coupon-overview-single-row.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-single-rows/assets/javascripts/coupon-overview-single-row.js');
"use strict";

const {
  Coupon
} = svs.components.tipsen.couponComponents;
const {
  useCurrentCouponRows
} = svs.components.tipsen.couponId;
const CouponOverviewSingleRow = _ref => {
  let {
    boardIndex
  } = _ref;
  const boardCouponRows = useCurrentCouponRows(boardIndex);
  return React.createElement("div", {
    className: "pg_coupon_overview_single_row"
  }, React.createElement("div", {
    className: "pg_coupon_overview_single_row__index"
  }, boardIndex + 1), boardCouponRows.map(row => React.createElement(Coupon.OutcomeRow, {
    className: "pg_coupon_overview_single_row__outcome_grid",
    key: row.eventNumber,
    outcomes: row.options,
    signDistribution: row.signDistribution,
    signLabel: row.mSign ? 'M' : ''
  })));
};
setGlobal('svs.components.tipsen.couponOverviewSingleRows.CouponOverviewSingleRow', CouponOverviewSingleRow);

 })(window);